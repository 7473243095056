.product-view__addtowishlist--update {
  .button__text {
    display: none;
  }
}

.wishlist-index-index {
  #wishlist-view-form {
    .wishlist__item {
      .amquote-addto-button {
        font-family: $font-family__base !important;
        margin-top: 10px;
        font-weight: 400;
      }
    }
    .actions-group__handler {
      .button {
        font-weight: 400;
      }
    }
  }
}