$product-list-item__border-style                          : $border-style-base !default;
$product-list-item__border-color                          : $border-color-base !default;
$product-list-item__border-width                          : 0 0 1px 0 !default;
$product-list-item__padding                               : $spacer--semi-medium 0 $spacer--medium !default;
$product-list-item__padding\@medium                       : $spacer--semi-medium 0 !default;
$product-list-item__transition                            : $transition-base !default;

$product-list-item__image-width                           : 80px !default;
$product-list-item__image-width\@medium                   : 160px !default;
$product-list-item__image-margin-right                    : $spacer !default;
$product-list-item__image-margin-right\@medium            : $spacer--medium !default;
$product-list-item__image-margin                          : 0 $product-list-item__image-margin-right 0 0 !default;
$product-list-item__image-margin\@medium                  : 0 $product-list-item__image-margin-right\@medium 0 0 !default;
$product-list-item__image-margin\@xl                      : 0 64px 0 0 !default;
$product-list-item__image-link-decoration                 : none !default;

$product-list-item__main-width                            : calc(100% - #{$product-list-item__image-width} - #{$product-list-item__image-margin-right}) !default;
$product-list-item__main-width\@medium                    : calc(100% - #{$product-list-item__image-width\@medium} - #{$product-list-item__image-margin-right\@medium}) !default;

$product-list-item__badges-z-index                        : $z-index-low !default;
$product-list-item__badges-top\@medium                    : $spacer--medium !default;
$product-list-item__badges-left                           : 0 !default;
$product-list-item__badges-bottom                         : -$spacer !default;
$product-list-item__badges-bottom\@medium                 : $spacer--large !default;

$product-list-item__name-font-size                        : $font-size-base !default;
$product-list-item__name-font-size\@medium                : $font-size-medium !default;
$product-list-item__name-margin                           : ($spacer * 0.5) 0 !default;
$product-list-item__name-margin\@medium                   : $spacer 0 ($spacer * 0.5) !default;
$product-list-item__name-text-transform                   : none !default;
$product-list-item__name-decoration                       : none !default;
$product-list-item__name-decoration-hover                 : none !default;
$product-list-item__name-color-hover                      : $color-primary !default;

$product-list-item__sku-font-size                         : $font-size-extra-small !default;
$product-list-item__sku-font-size\@medium                 : $font-size-base !default;
$product-list-item__sku-color                             : $color-secondary !default;
$product-list-item__sku-margin                            : 0 0 $spacer--medium !default;

$product-list-item__description-margin                    : 0 0 $spacer !default;
$product-list-item__description-font-size                 : $font-size-extra-small !default;
$product-list-item__description-font-size\@medium         : $font-size-base !default;
$product-list-item__description-color                     : $color-secondary !default;

$product-list-item__list-display                          : block !default;
$product-list-item__list-display\@medium                  : block !default;
$product-list-item__list-color                            : $color-secondary !default;
$product-list-item__list-font-size                        : $font-size-extra-small !default;
$product-list-item__list-font-size\@medium                : $font-size-base !default;
$product-list-item__list-margin                           : 0 !default;
$product-list-item__list-margin\@medium                   : 0 !default;
$product-list-item__list-label-padding                    : 0 $spacer $spacer $spacer--medium !default;

$product-list-item__price-width                           : 100% !default;
$product-list-item__price-margin\@medium                  : $spacer--extra-large 0 0 0 !default;
$product-list-item__price-align                           : right !default;
$product-list-item__price-font-size\@medium               : $font-size-extra-large !default;
$product-list-item__price-old-font-size\@medium           : $font-size-large !default;
$product-list-item__price-old-margin\@medium              : 0 !default;

$product-list-item__action-icon-fill                      : $gray-darker !default;
$product-list-item__action-margin                         : 0 $spacer--extra-small 0 0 !default;
$product-list-item__action-margin\@medium                 : 0 $spacer 0 0 !default;

$product-list-item__add-to-button-text-display            : none !default;
$product-list-item__add-to-button-text-display\@medium    : block !default;
$product-list-item__add-to-button-icon-display\@medium    : none !default;
$product-list-item__add-to-icon-display                   : none !default;
$product-list-item__add-to-icon-display\@medium           : block !default;
$product-list-item__add-to-icon-display\@xl               : none !default;

$product-list-item__stock-width                           : 100% !default;
$product-list-item__stock-display                         : none !default;
$product-list-item__stock-display\@medium                 : inline-flex !default;
$product-list-item__stock-decoration                      : none !default;
$product-list-item__stock-padding                         : 0 !default;
$product-list-item__stock-text-aligment                   : flex-start !default;
$product-list-item__stock-text-aligment\@medium           : flex-end !default;
$product-list-item__stock-text-transform                  : uppercase !default;
$product-list-item__stock-text-decoration-hover           : underline !default;

$product-list-item__swatch-loader-icon-margin             : 0 $spacer--semi-large 0 0 !default;

$product-list-item__wishlist-filled-bg-color-hover        : $color-primary !default;
$product-list-item__wishlist-filled-bg-image-after-hover  : linear-gradient(to right, $white 50%, transparent 50%) !default;

// EE Version
$product-list-item-ee__tocompare-background-color         : $gray-lighter !default;
$product-list-item-ee__towishlist-background-color        : $gray-lighter !default;
$product-list-item-ee__multiwishlist-width                : 48px !default;
$product-list-item-ee__multiwishlist-margin               : 0 $spacer 0 0 !default;
$product-list-item-ee__dropdown-list-content-top          : auto !default;
$product-list-item-ee__dropdown-list-content-bottom       : 100% !default;
$product-list-item-ee__dropdown-list-content-width        : 180px !default;
$product-list-item-ee__dropdown-list-content-width\@medium: 210px !default;
$product-list-item-ee__dropdown-list-label-width          : 48px !default;
$product-list-item-ee__dropdown-list-label-height         : 48px !default;
