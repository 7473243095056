@font-face {
  font-family: 'Helvetica Neue Bold';
  src:
          url('../fonts/Helvetica/HelveticaNeueBold.woff2') format('woff2'),
          url('../fonts/Helvetica/HelveticaNeueBold.woff') format('woff'),
          url('../fonts/Helvetica/HelveticaNeueBold.ttf') format('ttf'),
          url('../fonts/Helvetica/HelveticaNeueBold.otf') format('otf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue Heavy';
  src:
          url('../fonts/Helvetica/HelveticaNeueHeavy.woff2') format('woff2'),
          url('../fonts/Helvetica/HelveticaNeueHeavy.woff') format('woff'),
          url('../fonts/Helvetica/HelveticaNeueHeavy.ttf') format('ttf'),
          url('../fonts/Helvetica/HelveticaNeueHeavy.otf') format('otf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Anton Regular';
  src:
          url('../fonts/Anton/Anton-Regular.woff2') format('woff2'),
          url('../fonts/Anton/Anton-Regular.woff') format('woff'),
          url('../fonts/Anton/Anton-Regular.ttf') format('ttf'),
          url('../fonts/Anton/Anton-Regular.otf') format('otf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}