$icon__position: before;
$icon__text-hide: false;
$icon__height: 26px;
$icon__width: 26px;
$icon__margin: '';
$icon__vertical-align: middle;

$icon-image__position-x: 0;
$icon-image__position-y: 0;

$icon-sprite__position-x: 0;
$icon-sprite__position-y: 0;
$icon-sprite__grid: 26px;

$icon-font: $font-family-base;
$icon-font__position: $icon__position;
$icon-font__text-hide: $icon__text-hide;
$icon-font__margin: $icon__margin;
$icon-font__size: inherit;
$icon-font__line-height: $icon-font__size;
$icon-font__color: inherit;
$icon-font__color-hover: false;
$icon-font__color-active: false;
$icon-font__vertical-align: $icon__vertical-align;
$icon-font__display: inline-block;

$icon-calendar__font-size: 40px;

//
//  Variables for icons-blank-theme
//  ---------------------------------------------

// ToDo UI: move it to blank theme variables
$icon-wishlist-full: '\e600';
$icon-wishlist-empty: '\e601';
$icon-warning: '\e602';
$icon-update: '\e603';
$icon-trash: '\e604';
$icon-star: '\e605';
$icon-settings: '\e606';
$icon-pointer-down: '\e607';
$icon-next: '\e608';
$icon-menu: '\e609';
$icon-location: '\e60a';
$icon-list: '\e60b';
$icon-info: '\e60c';
$icon-grid: '\e60d';
$icon-comment-reflected: '\e60e';
$icon-collapse: '\e60f';
$icon-checkmark: '\e610';
$icon-cart: '\e611';
$icon-calendar: '\e612';
$icon-arrow-up: '\e613';
$icon-arrow-down: '\e614';
$icon-search: '\e615';
$icon-remove: '\e616';
$icon-prev: '\e617';
$icon-pointer-up: '\e618';
$icon-pointer-right: '\e619';
$icon-pointer-left: '\e61a';
$icon-flag: '\e61b';
$icon-expand: '\e61c';
$icon-envelope: '\e61d';
$icon-compare-full: '\e61e';
$icon-compare-empty: '\e61f';
$icon-comment: '\e620';
$icon-up: '\e621';
$icon-down: '\e622';
$icon-help: '\e623';
$icon-arrow-right-thin: '\e624';
$icon-arrow-left-thin: '\e625';
$icon-arrow-down-thin: '\e626';
$icon-account: '\e627';
$icon-gift-registry: '\e628';
$icon-present: '\e629';
$icon-arrow-up-thin: '\e633';
$icon-wishlist-full: '\e600';
$icon-wishlist-empty: '\e601';
$icon-warning: '\e602';
$icon-update: '\e603';
$icon-trash: '\e604';
$icon-star: '\e605';
$icon-settings: '\e606';
$icon-pointer-down: '\e607';
$icon-next: '\e608';
$icon-menu: '\e609';
$icon-location: '\e60a';
$icon-list: '\e60b';
$icon-info: '\e60c';
$icon-grid: '\e60d';
$icon-comment-reflected: '\e60e';
$icon-collapse: '\e60f';
$icon-checkmark: '\e610';
$icon-cart: '\e611';
$icon-calendar: '\e612';
$icon-arrow-up: '\e613';
$icon-arrow-down: '\e614';
$icon-search: '\e615';
$icon-remove: '\e616';
$icon-prev: '\e617';
$icon-pointer-up: '\e618';
$icon-pointer-right: '\e619';
$icon-pointer-left: '\e61a';
$icon-flag: '\e61b';
$icon-expand: '\e61c';
$icon-envelope: '\e61d';
$icon-compare-full: '\e61e';
$icon-compare-empty: '\e61f';
$icon-comment: '\e620';
$icon-up: '\e621';
$icon-down: '\e622';
$icon-arrow-up-thin: '\e623';
$icon-arrow-right-thin: '\e624';
$icon-arrow-left-thin: '\e625';
$icon-arrow-down-thin: '\e626';

@mixin lib-button-reset() {
    background-image: none; // Reset unusual Firefox-on-Android default style
    -moz-box-sizing: content-box; // Hack: fix Firefox button line-height problem
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;

    &:focus,
    &:active {
        background: none;
        border: none;
    }

    &:hover {
        background: none;
        border: none;
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        pointer-events: none; // Disabling of all pointer events
        opacity: .5;
    }
}

@mixin lib-button-icon(
$_icon-font-content,
$_icon-font: $icon-font,
$_icon-font-size: $icon-font__size,
$_icon-font-line-height: $icon-font__line-height,
$_icon-font-color: $icon-font__color,
$_icon-font-color-hover: $icon-font__color-hover,
$_icon-font-color-active: $icon-font__color-active,
$_icon-font-margin: $icon-font__margin,
$_icon-font-vertical-align: $icon-font__vertical-align,
$_icon-font-position: $icon-font__position,
$_icon-font-text-hide: $icon-font__text-hide,
$_icon-font-display: $icon-font__display
)  {
    display: $_icon-font-display;
    text-decoration: none;

    @if $_icon-font-text-hide == true {
        > span {
            border: 0;
            clip: rect(0, 0, 0, 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }
    }

    &:hover {
        &:before {
            color: $_icon-font-color-hover;
        }
    }

    &:active {
        &:before {
            color: $_icon-font-color-active;
        }
    }
    
    @if $_icon-font-position == 'after' {
        &:after {
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-size: $_icon-font-size;
            line-height: $_icon-font-line-height;
            color: $_icon-font-color;
            content:  $_icon-font-content;
            font-family: $_icon-font;
            margin: $_icon-font-margin;
            vertical-align: $_icon-font-vertical-align;
            display: inline-block;
            font-weight: normal;
            overflow: hidden;
            speak: none;
            text-align: center;
        }
    } @else if $_icon-font-position == 'before' {
        &:before {
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-size: $_icon-font-size;
            line-height: $_icon-font-line-height;
            color: $_icon-font-color;
            content:  $_icon-font-content;
            font-family: $_icon-font;
            margin: $_icon-font-margin;
            vertical-align: $_icon-font-vertical-align;
            display: inline-block;
            font-weight: normal;
            overflow: hidden;
            speak: none;
            text-align: center;
        }
    }
}