//
//  Improved Layered Navigation
//  ____________________________________________

$amshopby-loader__background-image: url('../images/svg/loader.svg');
$amshopby-loader__size: 100px;

.page-products {
  .filter-content {
    padding-bottom: 20px;

    .label {
      text-transform: uppercase;
      margin: 0;
      flex-basis: 100%;
    }

    .swatch-option {
      &.color {
        width: 24px;
        height: 24px;
        margin: 0 5px;
        border-radius: 15px;
      }
    }
    .filter-options-content {
      .list {
        &.-am-multiselect {

          &.am-filter-items-attr_category_ids {
            margin: 0;
          }
          .item {
            padding: 0;
            clear: both;
            min-height: 30px;

            &.-is-collapsible,
            &.-is-expanded {
              padding: 0;
            }

            a {
              position: relative;
              width: calc(100% - 20px);
              float: right;
              margin-top: -3px;
            }
            input {
              margin-top: 6px;
            }
          }

          .checkbox__label {
            padding: 0 0 0 32px;
            word-break: break-all;
            text-transform: capitalize;
          }

          .filter-count-label {
            padding-left: 5px;
          }

          .-is-collapsible {
            .am-collapse-icon {
              left: 0;
              &:after {
                margin-top: 0;
                top: 6px;
              }
              &.-active {
                width: auto;
                cursor: default !important;
                &:after {
                  transform: rotate(0);
                  left: 0;
                  cursor: pointer;
                }
              }
            }
          }
        }
        .item {
          margin-bottom: 15px;
          display: block;
          input {
            order: 0;
          }
          a {
            order: 1;
          }
          .count {
            display: none;
          }
        }
      }
      .am-swatch-options {
        .am-swatch-link {
          align-items: center;
          width: 100%;
          .swatch-option {
            border: 1px solid #dadada;
            cursor: pointer;
            height: 20px;
            flex-basis: 10%;
            overflow: hidden;
            padding: 1px 2px;
            position: relative;
            text-align: center;
            text-overflow: ellipsis;
          }
          .am-shopby-swatch-label {
            position: relative;
            max-width: unset;
            display: inline-flex;
            justify-content: space-between;
            flex-basis: 85%;
          }
        }
      }

      .amshopby-slider-wrapper {
        margin: 40px 0;

        .amshopby-slider-tooltip {
          position: absolute;
          bottom: 25px;
        }
        .ui-slider-handle {
          &:last-child {
            .amshopby-slider-tooltip {
              top: 25px;
              right: 0;
            }
          }
        }
        .amshopby-slider-display {
          display: none;
        }
      }

      .am-shopby-form {
        margin: 0;
        padding: 0;
        .swatch-attribute {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          > .item {
            padding-top: 5px;
            flex-basis: 20%;
            .am-swatch-link {
              margin-bottom: 5px;
            }
          }
        }
      }
    }

    .filter-options-item {
      .filter-options-title {
        position: relative;
        cursor: pointer;
        padding: 0 48px 0 0;
        .filters__heading {
          text-transform: initial;
          font-size: 16px;
        }
      }
      &.active {
        .dropdown-list__icon {
          transform: rotate(180deg);
          right: 0;
        }
      }
    }

    .filter-subtitle {
      font-size: 24px;
      padding: 15px 0 0;
      display: block;
    }

    .modal-header {
      display: none;
      position: fixed;
      right: 0;
      top: 0;
      .block-subtitle {
        background: #fff !important;
        padding: 16px;
      }
      .modal__close-button {
        top: 5px;
        z-index: 99999;
        background: none;
      }
    }

    .amshopby-filter-current {
      .amshopby-items {
        padding: 0;
      }
    }
    .filter-actions {
      .filter-clear {
        font-family: $font-family__base;
        font-weight: $font-weight__regular;
        color: $white;
        &:before {
          border-radius: 9px;
        }
        &:hover {
          color: $color-primary;
        }
      }
    }
  }

  .amshopby-overlay-block {
    & {
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 230;
      display: none;
      width: 100%;
      background: rgba(255, 255, 255, .5);
    }

    > .amshopby-loader {
      position: absolute;
      top: 10%;
      left: 50%;
      display: block;
      width: $amshopby-loader__size;
      height: $amshopby-loader__size;
      background: $amshopby-loader__background-image center/$amshopby-loader__size no-repeat;
      transform: translate(-50%, -10%);
    }
  }

  .sidebar-block__title {
    text-transform: capitalize;
  }
  .sidebar-additional {
    display: none;
  }
}

@include mq($max-screen: $screen__m - 1) {
  .page-products {
    #maincontent {
      margin-top: 222px;
    }

    .columns {
      .column.main {
        padding-bottom: 40px;
        -webkit-flex-basis: auto;
        flex-basis: auto;
        -webkit-flex-grow: 1;
        flex-grow: 1;
        -ms-flex-order: 1;
        -webkit-order: 1;
        order: 1;
        width: 100%;
      }
      .sidebar-main {
        -ms-flex-order: 0;
        -webkit-order: 0;
        order: 0;
        .filter {
          .filter-content {
            padding-bottom: 0;
          }
          .filter-options {
            padding: 16px;
            display: none;
            margin: 0;
            top: 70px;
          }
          .filter-title {
            position: fixed;
            top: 131px;
            left: 0;
            background-color: $white;
            z-index: 80;
            strong {
              border-radius: 3px;
              font-weight: 400;
              line-height: 16px;
              padding: 7px 10px;
              position: absolute;
              text-align: center;
              z-index: 2;
            }
          }
          &.active {
            position: relative;
            visibility: visible;
            z-index: 99999;
            .modal-header {
              display: block;
            }
            .filter-title {
              display: none;
            }

            .filter-subtitle {
              background: transparent;
              display: block;
              height: 70px;
              border-bottom: 1px solid #d8d8d8;
              left: 0;
              line-height: 32px;
              position: fixed;
              right: 0;
              top: 0;
              z-index: 1;
            }

            .filter-options {
              background: #ffffff;
              bottom: 0;
              display: block;
              left: 0;
              overflow: scroll;
              position: fixed;
              right: 0;
              z-index: 10;
            }
          }
        }
      }
    }

    .header-marketing-bar--close {
      #maincontent {
        margin-top: 176px;
      }
      .columns {
        .sidebar-main {
          .filter {
            .filter-title {
              top: 94px;
            }
          }
        }
      }
    }
  }
}

@include mq($screen__m) {
  .page-products {
    .container {
      margin-left: auto;
      margin-right: auto;
    }
    .sidebar-main {
      height: calc(100vh - 81px);
      position: -webkit-sticky;
      position: sticky;
      top: 110px;
      padding: 0 10px;
    }

    .header-marketing-bar--close {
      .sidebar-main {
        top: 80px;
      }
    }
  }
}
@include mq($screen__l) {
  .page-products {
    .sidebar-main {
      height: calc(100vh - 180px);
      overflow-y: auto;
      top: 220px;
    }
    .header-marketing-bar--close {
      .sidebar-main {
        top: 190px;
      }
    }
  }
}
