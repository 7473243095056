//
//  Variables
//  __________________________________________

$amshopby__primary__color: #1979c3;
$amshopby__hover__color: lighten($amshopby__primary__color, 13%);
$amshopby__pressed__color: darken($amshopby__primary__color, 10%);

$amshopby-base__border-color: #ccc;
$amshopby-dropdown__border-color: #adadad;
$amshopby-search__highlighted__background-color: #ff0;
$amshopby-filter-item__selected__background-color: #e8e8e8;
$amshopby-collapse-icon__size: 20px;
$amshopby-loader__url: url('../images/svg/loader.svg');
