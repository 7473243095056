a[data-element=link] {
    display: inline-block;
    width: 100%;
    min-height: inherit;
    overflow: hidden;
}

.projects-index-index,
.project-view-index {
    .page-title {
        padding: 20px 0 10px;
        margin-bottom: 20px;
        text-transform: uppercase;
        background: $gray-lightest;

        .title {
            font-size: 2em;
            text-align: center;
            font-weight: 500;
            color: $black;
        }
        .breadcrumbs {
            background-color: #f6f6f8;
            margin-bottom: 0;
            padding: 0;
            margin-top: -10px;
            ul {
                display: flex;
                justify-content: center;
                list-style: none;
                padding: 0;
                align-items: flex-end;
                li {
                    color: $gray-dark;
                    font-weight: 400;
                    a {
                        color: $gray-dark;
                        align-items: flex-end;
                        .icon {
                            fill: $gray-dark;
                        }
                    }
                }
            }
        }
    }
    .page-main {
        text-align: center;
        .projects_management_title {
            h1 {
                font-size: 24px;
                font-weight: 600;
                letter-spacing: 4px;
                margin: 0;
            }
        }
        .projects_management_subtitle {
            p {
                color: $gray-dark;
                margin: 0;
                font-size: 1.167em;
            }
            margin-bottom: 40px;
        }
        .projects_management_data {
            margin: 0 auto;

            .projects_management_wrapper {
                display: block;
                width: 100%;
                position: relative;
                overflow: hidden;
                .arw-banner-content {
                    text-align: center;
                    padding: 40px;
                    width: 100%;
                    left: 0;
                    position: absolute;
                    top: 50%;
                    -webkit-transform: translate(0%,-50%);
                    -moz-transform: translate(0%,-50%);
                    -ms-transform: translate(0%,-50%);
                    transform: translate(0%,-50%);
                    opacity: 0;
                    filter: alpha(opacity=0);
                    -webkit-transition: all .5s ease;
                    -moz-transition: all .5s ease;
                    -ms-transition: all .5s ease;
                    transition: all .5s ease;
                    z-index: 2;
                    .banner-title {
                        font-size: 17px;
                        color: $white;
                        letter-spacing: 3px;
                        margin-bottom: 15px;
                        margin-top: 18%;
                        -webkit-transform: translateX(-500px);
                        -moz-transform: translateX(-500px);
                        -ms-transform: translateX(-500px);
                        transform: translateX(-500px);
                        -webkit-transition: all .2s ease .15s;
                        -moz-transition: all .2s ease .15s;
                        -ms-transition: all .2s ease .15s;
                        transition: all .2s ease .15s;
                    }
                }
            }
        }
    }
    .page-wrapper {
        margin-bottom: 0;
    }
}

.projects-index-index {
    .page-main {
        .heading--page {
            display: none;
        }
        .filter {
            ul {
                padding: 0;
                display: flex;
                list-style: none;
                margin-bottom: 30px;
                li {
                    margin-right: 30px;
                    &.active {
                        .title {
                            color: $color-primary;
                        }
                    }
                    .title {
                        cursor: pointer;
                        font: normal normal bold 14px/17px Helvetica;
                        color: $border-color-base;
                    }
                }
            }
        }
        .projects_management_data {
            margin: 0 -6px;
            .projects_management_banner {
                padding: 6px;
                .projects_management_wrapper {
                    &:hover {
                        color: $border-color-base;
                        text-decoration: none;
                    }
                    .projects_management_image {
                        img {
                            object-fit: cover;
                            width: 100%;
                        }
                    }
                    .project-info {
                        text-align: left;
                        color: $border-color-base;
                        padding: 20px 0 0;
                        .project-name {
                            font: normal normal bold 14px/17px Helvetica;
                            margin-bottom: 20px;
                            display: block;
                        }
                    }
                }
                .project-short-description {
                    text-align: left;
                    padding-bottom: 20px;
                    color: $border-color-base;
                    font: normal normal normal 12px/14px Helvetica;
                }
            }
            .toolbar {
                margin: 0 0 60px;
                border: none;
                .toolbar__amount {
                    display: none;
                }
                .pager__item {
                    display: none;
                }
                li {
                    &:first-child:not(.pager__item--current),
                    &:last-child:not(.pager__item--current) {
                        display: block;
                        position: absolute;
                        .button {
                            background: $white;
                            border-radius: 10px;
                            box-shadow: $shadow;
                            &:hover {
                                background: $color-secondary;
                                .icon {
                                    fill: $white;
                                }
                            }
                            .icon {
                                stroke: transparent;
                                fill: $color-primary;
                            }
                        }
                    }

                    &:first-child {
                        left: 20px;
                        &:after {
                            content: 'Prev';
                            position: absolute;
                            top: 10px;
                            left: 80px;
                            font: normal normal bold 20px/24px Helvetica;
                        }
                    }

                    &:last-child {
                        right: 20px;
                        &:after {
                            content: 'Next';
                            position: absolute;
                            top: 10px;
                            right: 80px;
                            font: normal normal bold 20px/24px Helvetica;
                        }
                    }
                }
            }
        }
    }
}
.project-view-index {
    .heading--page {
        display: none;
    }
    .main-wrapper {
        @include mq($screen-m) {
            display: flex;
            padding-top: 25px;
            .project-info {
                flex-basis: 30%;
            }
            .gallery {
                padding-left: 40px;
            }
        }
        .project-info {
            text-align: left;
            .breadcrumbs {
                ul {
                    display: flex;
                    padding: 0;
                    list-style: none;
                    .divider {
                        margin: 0 10px;
                    }
                    li {
                        line-height: 1.6;
                    }
                }
            }
            .project-description {
                color: $border-color-base;
            }
            .project-more-info {
                margin: 50px 0;
                list-style: none;
                padding: 0;
                li {
                    &:first-child {
                        border-top: 2px solid $button__bg--icon;
                    }
                    padding: 10px 0;
                    border-bottom: 2px solid $button__bg--icon;
                    display: flex;
                    .label, .value {
                        flex-basis: 50%;
                        padding-right: 10px;
                    }
                    .label {
                        text-transform: uppercase;
                    }
                    .value {
                        color: $border-color-base;
                    }
                }
            }
        }
        .gallery {
            flex-basis: 70%;
            #show-selected-image {
                img {
                    object-fit: contain;
                    height: 400px;
                    width: 100%;
                }
            }
            .nav {
                text-align: left;
                padding: 0;
                list-style: none;
                width: 100%;
                overflow-x: auto;
                display: inline-block;
                height: 150px;
                white-space: nowrap;
                li {
                    display: inline-block;
                    img {
                        height: 120px;
                    }
                }
            }
        }
    }
    .thumbnail {
        margin: 60px auto;
        img {
            width: 100%;
        }
    }
    .product-list {
        .title {
            margin-bottom: 30px;
        }
    }
}

.projects-index-index {
    @include mq($max-screen: $screen__m - 1) {
        .page-main {
            .filter {
                ul {
                    flex-wrap: wrap;
                    li {
                        margin-bottom: 10px;
                    }
                }
            }

            .projects_management_data {
                padding: 0 20px;
                .projects_management_banner{
                    padding: 5px 5px 30px;
                }
                .toolbar {
                    li {
                        &:first-child,
                        &:last-child {
                            &:after {
                                content: none;
                            }
                        }
                    }
                }
            }
        }
    }

    .bseated-project-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
        display: flex;
        align-items: center;
        justify-content: center;
        display: none;
    }
}
