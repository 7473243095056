.contact-index-index {
  .heading--page {
    display: none;
  }

  .wrapper {
    > div {
      text-align: left;
      .text-uppercase {
        font-weight: 600;
      }
      .form {
        @include mq($screen-m) {
          padding-right: 100px;
        }
        margin-bottom: 50px;
      }

      .list-info {
        .info-item {
          display: flex;
          margin: 20px 0 40px;
          .icon {
            margin-right: 30px;
            width: 50px;
            height: 50px;
          }
        }
      }
    }
  }
}