[data-content-type='row'][data-appearance='contained'] {
    box-sizing: border-box;
    margin-left: auto !important;
    margin-right: auto !important;
    max-width: $container__max-width;
    width: 100%;
}
[data-content-type='image'] {
    picture {
        img {
            display: unset;
        }
    }
}