//
//  Shop by Base
//  ____________________________________________

//
//  Variables
//  --------------------------------------------

$base-border__color: #cccccc;

//
//  Common
//  --------------------------------------------

//Firefox percentage width of flex element bug fix
.columns .column.main {
    max-width: 100%;
}

.icon .brand-label {
    float: left;
}

.amshopby-morefrom {
    .products-grid .product-item {
        width: auto;
    }

    .product-image-container {
        min-width: auto;
    }
}

.ambrands-menu-item.parent .ambrands-link.level-top:after {
    content: '';
}

//
//  Tablet
//  --------------------------------------------

@include mq($screen__m) {
    .am-brands-fullwidth {
        width: 100% !important;
    }
}
