.frequently-asked-title {
    text-align: center;
    font-size: 32px;
}

.frequently-asked-content {
    text-align: center;
    word-wrap: break-word;
    
    .amfaq-title {
        font-size: 30px !important;
    }
}

.um-descr-show-button, .um-descr-hide-button {
    padding: 0 15px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 1328px;
    text-align: right;
}

.catalog-category-view {
    .page-wrapper {
        margin-bottom: unset !important;
    }

    .cat_frequently_ask_wrapper {
        margin-top: 1.5rem;
        max-width: 768px;
        margin-left: auto;
        margin-right: auto;
    }

    .frequently-asked-title {
        font-size: 16px !important;
    }
    
    .frequently-asked-content {
        .amfaq-title {
            font-size: 14px !important;
        }
    }
}
