@import 'footer-variables';

.footer {
  flex-shrink: 0;
  background-color: $footer__background-color;

  &--checkout {
    .footer__bottom-bar-handler {
      padding: $footer__bottom-bar-handler-padding--checkout;

      @include mq($screen-m) {
        padding: $footer__bottom-bar-handler-padding--checkout\@medium;
      }

      @include mq($screen-l) {
        padding: $footer__bottom-bar-handler-padding--checkout\@large;
      }

      @include mq($screen-xl) {
        padding: $footer__bottom-bar-handler-padding--checkout\@extra-large;
      }

      > div {
        display: none;

        &.copy-right {
          display: block;
        }
      }
    }
  }

  &--email {
    background-color: $footer__background-color--email;
  }

  &__handler {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: $footer__handler-padding;

    @include mq($screen-m) {
      padding: $footer__handler-padding\@medium;
    }

    @include mq($screen-l) {
      flex-flow: row wrap;
      padding: $footer__handler-padding\@large;
    }
  }

  &__newsletter {
    border: $footer__newsletter-border;
    border-width: $footer__newsletter-border-width;

    @include mq($screen-l) {
      width: $footer__newsletter-width\@large;
      padding: $footer__newsletter-padding\@large;
      margin: $footer__newsletter-margin\@large;
    }
  }

  &__links {
    width: $footer__links-width;
    padding: $footer__links-padding;
    color: $white;

    a, span {
      color: $white;

      &:hover {
        color: $footer__link-color-hover;
        text-decoration: none;
      }
    }

    @include mq($screen-l) {
      padding: $footer__links-padding\@large;
    }

    .dropdown-list {
      background-color: $footer__links-dropdown-background;

      @include mq($screen-m) {
        padding: $footer__links-dropdown-padding\@medium;
      }

      &__label {
        background-color: $footer__links-label-background;
        color: $footer__links-label-color;

        &:hover,
        &.focus-visible {
          & > .dropdown-list__icon {
            fill: $footer__links-dropdown-icon-color-hover;
          }
        }

        &[aria-expanded="true"] {
          & > .dropdown-list__icon {
            fill: $footer__links-dropdown-icon-color-open;
          }
        }
      }

      &__content {
        margin: $footer__links-content-margin;
      }

      &__list {
        margin: 0;
        border: $footer__links-dropdown-list-border;
        border-width: $footer__links-dropdown-list-border-width;

        @include mq($screen-m) {
          padding: $footer__links-dropdown-list-padding\@medium;
        }

        @include mq($screen-l) {
          padding: $footer__links-dropdown-list-padding\@large;
          border-width: $footer__links-dropdown-list-border-width\@large;
        }

        @include mq($screen-xl) {
          padding: $footer__links-dropdown-list-padding\@extra-large;
        }
      }

      &__item {
        width: $footer__links-dropdown-list-item-width;
        border-bottom: $footer__links-dropdown-list-item-border;

        @include mq($screen-m) {
          border: none;
        }

        &:first-child {
          @include mq($screen-m) {
            width: $footer__links-dropdown-list-item-width-first-child\@medium;
          }
        }

        &:last-child {
          border: none;
        }

        .logo {
          max-width: 130px;
          margin-bottom: 28px;
          @include mq($screen-m) {
            margin-bottom: 38px;
          }
          margin-top: 20px;
        }
      }

      &__icon {
        fill: $footer__links-dropdown-icon-color;
      }
    }
  }

  &__links-list {
    padding: $footer__links-list-padding;
  }

  &__link {
    display: block;
    margin: $footer__link-margin;
    font-size: $footer__link-font-size;
    font-weight: $footer__link-font-weight;
    color: $footer__link-color;
    text-decoration: none;

    @include mq($screen-m) {
      margin: $footer__link-margin\@medium;
    }

    &:hover {
      color: $footer__link-color-hover;
      text-decoration: none;
    }
  }

  &__social {
    order: 1;

    @include mq($screen-l) {
      order: 0;
    }
  }

  &__social-handler {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: $footer__social-handler-margin;

    @include mq($screen-m) {
      align-items: center;
      flex-direction: row;
    }

    @include mq($screen-l) {
      flex-direction: column;
      flex: 1;
      margin: $footer__social-handler-margin\@large;
      padding: $footer__social-handler-padding\@large;
      border-bottom: $footer__newsletter-border;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 1px;
        height: 90%;
        background: $gray;
      }
    }
  }

  &__social-list {
    @include mq($screen-l) {
      align-self: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
    }

    .list__item {
      padding: $footer__social-list__item-padding;
      margin: $footer__social-list__item-margin;

      &:last-child {
        margin: $footer__social-list__item-margin-last-child;
      }
    }

    .list__icon-link {
      width: $footer__social-list__icon-link-size;
      height: $footer__social-list__icon-link-size;
      padding: $footer__social-list__icon-link-padding;

      @include mq($screen-l) {
        margin: $footer__social-list__icon-link-margin\@large;
      }
    }
  }

  &__social-list-title {
    display: flex;
    align-items: center;
    color: $footer__social-list-title-color;
    font-family: $footer__social-list-title-font-family;
    font-size: $footer__social-list-title-font-size;
    font-weight: $footer__social-list-title-font-weight;
    margin: $footer__social-list-title-margin;

    @include mq($screen-m) {
      margin: $footer__social-list-title-margin\@medium;
    }

    @include mq($screen-l) {
      align-self: flex-start;
      margin: $footer__social-list-title-margin\@large;
    }
  }

  &__bottom-bar {
    color: $footer__bottom-bar-color;
    background-color: $footer__bottom-bar-background;
  }

  &__bottom-bar-handler {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    min-height: $footer__bottom-min-height;

    p {
      font-size: 14px;
    }

    @include mq($screen-m) {
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 30px 0 30px;
    }

    .footer__links {
      width: unset;
    }
  }

  &__top-bar {
    @include mq($screen-m) {
      border-bottom: 2px solid $gray-light;
    }
  }

  &__copyright {
    display: block;
    order: 2;
    padding: $footer__copywrite-padding;
    color: $footer__copywrite-color;

    @include mq($screen-m) {
      order: 1;
      padding: $footer__copywrite-padding\@medium;
    }

    small {
      font-size: $footer__copywrite-font-size;
    }
  }

  &__payments-list {
    justify-content: space-around;
    margin: $footer__payments-list-margin;

    @include mq($screen-m) {
      flex-flow: row wrap;
      margin: $footer__payments-list-margin\@medium;
      order: 2;
    }
  }

  &__payments-list-icon {
    width: $footer__payments-list-icon-width;
    height: $footer__payments-list-icon-height;
    fill: $footer__payments-list-icon-fill;

    &--visa {
      width: $footer__payments-list-icon-width--visa;
    }

    &--paypal {
      width: $footer__payments-list-icon-width--paypal;
    }
  }

  &__scroll-top {
    position: absolute;
    right: $footer__scroll-top-right;
    top: $footer__scroll-top-top;
    background-color: $footer__scroll-top-bg;

    &:before {
      transform: $footer__scroll-top-before-transform;
      background: none;
    }

    &:hover,
    &:focus,
    &.focus-visible {
      background-color: $footer__scroll-top-bg-hover;
    }

    .icon {
      fill: $footer__scroll-top-icon-fill;
    }
  }

  &__switchers {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: $footer__switchers-padding;
    order: 0;

    @include mq($screen-s) {
      flex-direction: row;
      justify-content: center;
    }
  }

  &__dropdown-switcher {
    width: auto;
    background-color: $footer__switcher-background-color;

    .dropdown-list__label {
      padding: $footer__switcher-label-padding;
      color: $footer__switcher-label-color;
      white-space: nowrap;
      background: none;

      &[aria-expanded=true] {
        color: $footer__switcher-label-background-color;

        &:hover {
          .dropdown-list__icon {
            fill: $footer__switcher-label-background-color;
          }
        }
      }

      &:hover {
        .dropdown-list__icon {
          fill: $footer__switcher-label-color;
        }
      }
    }

    .dropdown-list__content {
      position: absolute;
      bottom: $footer__switcher-content-bottom;
      width: $footer__switcher-content-width;
      padding: $footer__switcher-content-padding;
      background-color: unset;

      .button {
        font-weight: $footer__switcher-button-font-weight;
        min-height: $footer__switcher-button-min-height;
        text-decoration: $footer__switcher-button-text-decoration;

        &:hover,
        &:focus,
        &.focus-visible {
          text-decoration: $footer__switcher-button-hover-text-decoration;
        }
      }

      .link {
        color: $white;
      }
    }

    .dropdown-list__icon {
      fill: $footer__switcher-label-color;
    }
  }

  .button--icon-border {
    border: none;
    &:hover,
    &:focus,
    &.focus-visible {
      border: none;
    }
  }
}
