$catalog-grid__gap    : $spacer--medium !default;
$catalog-grid__margin : 0 !default;
$catalog-grid__padding: $spacer--medium 0 $spacer--large 0 !default;

.catalog-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: $catalog-grid__gap;
    margin: $catalog-grid__margin;
    padding: $catalog-grid__padding;
    list-style: none;

    @include mq($screen-s) {
        grid-template-columns: 1fr 1fr;
    }

    @include mq($screen-m) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @include isIE() {
        display: flex;
        flex-wrap: wrap;
    }
}

@include mq($screen-m) {
    .page-products {
        .column {
            &.main {
                padding-left: 15px;
            }
        }
    }
}