.cms-index-index {
  .width-full-screen {
    [data-content-type="banner"] {
      > a {
        display: block;
      }
    }
  }
  @include mq($max-screen: $screen__m) {
    .main {
      > .home {
        padding: 0 20px;
      }
    }
  }

  .center-image-section {
    > .pagebuilder-column-group {
      > .pagebuilder-column {
        box-shadow: 0 11px 12px rgba(0, 0, 0, 0.1);
        padding-bottom: 10px;
        > figure {
          margin: 1em 0;
        }
        [data-content-type="text"] {
          p {
            margin-bottom: 0;
          }
          @include mq($max-screen: $screen__l) {
            span {
              font-size: 23px !important;
            }
          }
        }

        .pagebuilder-button-link {
          background: $color-background-link-button;
          padding: 0 15px;
          color: $white;
          font-size: 24px;
          min-width: 146px;
          min-height: 40px;
          border-radius: 4px;
          text-transform: lowercase;
          border: none;
          text-decoration: none;
        }
      }
    }
  }

  .category-image-section {
    .pagebuilder-column-group {
      justify-content: space-between;
    }
    .pagebuilder-column {
      box-shadow: 0 10px 13px rgba(0, 0, 0, 0.1);
      flex-basis: 48%;

      @include mq($screen-m) {
        flex-basis: 22%;
      }
      margin-bottom: 20px;
      > figure {
        margin: 0 0 10px;
      }
    }
  }

  .latest-styles {
    padding: 0 15px;
    .pagebuilder-column-group {
      justify-content: space-between;
      .pagebuilder-column {
        position: relative;
        margin-bottom: 30px;
        @include mq($screen-m) {
          flex-basis: 22%;
        }

        > figure {
          margin: 0;
          img {
            width: 100%;
          }
        }

        [data-content-type="text"] {
          background-color: rgba(0, 174, 239, 0.6);
          width: 100%;
          height: 100%;
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          a {
            color: $white;
            font-weight: 700;
            text-transform: capitalize;
            font-size: 36px !important;
            text-decoration: none;
            @include mq($max-screen: $screen__l) {
              font-size: 24px !important;
            }
            @include mq($max-screen: $screen__m - 1) {
              font-size: 37px !important;
            }
          }
        }
      }
    }
  }

  .section-2 {
    border-bottom: 2px solid $button__bg--icon;
    .pagebuilder-column {
      display: flex;
      flex-direction: row !important;
      justify-content: center;
      align-items: flex-start;
      .icon {
        width: 45px;
        height: 45px;
        margin-right: 30px;
      }
      strong {
        font-weight: 400;
        font-family: $font-family__base;
      }
    }
  }

  .ui-tabs {
    .tabs-navigation {
      li.tab-header {
        background: unset;
        border: none;
        margin: 0 15px 30px;
        a.tab-title {
          padding: 15px 32px;
          font-family: $font-family__base;
          font-weight: 400;
        }
        &.ui-tabs-active {
          background: unset;
          border: 1px solid $border-color-base;
          border-radius: 10px !important;
        }
      }
    }
    .tabs-content {
      min-height: unset !important;
      .ui-tabs-panel {
        display: block !important;
        height: 0;
        overflow: hidden;
        &[aria-expanded="true"] {
          height: auto;
        }
      }
    }
  }

  .section-6 {
    .download {
      span {
        text-transform: capitalize;
      }
      .icon {
        margin-left: 10px;
        width: 10px;
      }
    }
  }
  .section-8 {
    .slider__after {
      margin-top: 50px;
      a {
        font-size: 16px;
        color: $border-color-base;
      }
    }
  }

  .section-9 {
    .pagebuilder-button-primary {
      text-transform: capitalize;
    }
  }

  .pagebuilder-mobile-only {
    .slider--products {
      .slider__navigation {
        display: block;
        top: 90%;
        .slider__prev {
          left: 0;
        }
        .slider__next {
          right: unset;
          left: 60px;
        }
      }
    }
    &.category-banner {
      position: relative;
      figure {
        margin: 0;
      }
      .category-banner-text,
      .category-banner-button {
        position: absolute;
        left: 20px;
      }
      .category-banner-text {
        bottom: 90px;
        &.top {
          bottom: unset;
          top: 15px;
        }
      }
      .category-banner-button {
        bottom: 20px;
      }
    }
  }
}

.am-widget-brand-slider {
  .amslider {
    .slider__navigation {
      top: 30%;
      .swiper-button {
        &-next,
        &-prev {
          top: 35%;
          background: none;

          .icon {
            position: relative;
            top: unset;
            left: unset;
          }
        }

        &-prev {
          left: -50px;
        }

        &-next {
          right: -50px;
        }
      }
    }
  }
}

.footer__links {
  .dropdown-list__list {
    border: none;
    padding: 15px 0 0;
    @include mq($max-screen: $screen__l) {
      padding: 10px 0 0;
      display: flex;
      flex-wrap: wrap;
      li.dropdown-list__item {
        flex-basis: 50%;

        &:first-child, &:last-child {
          flex-basis: 100%;
        }
        h3 {
          text-transform: capitalize;
          margin: 0 0 10px;
        }
        .footer__social-list-title {
          display: none;
        }
        .footer__social-list {
          justify-content: flex-start;
        }
        .list__item {
          padding: 0;
        }
      }
    }
    .dropdown-list__item {
      border: none;
      @include mq($screen-m) {
        padding-right: 20px;
      }
      &:first-child {
        width: 100%;
        .column-shop-info {
          .info-line {
            display: flex;
            padding-bottom: 20px;
            .icon {
              margin-right: 30px;
              fill: white;
              width: auto;
              max-width: 24px;
            }
          }
        }
      }
      h3 {
        color: $white;
        font-size: 14px;
        font-family: $font-family__base;
        font-weight: 400;
        margin: 20px 0 40px;
      }
      .footer__social-list {
        .list__item {
          margin-right: 20px;
          min-width: unset;
          .list__icon-link {
            background: unset;
            min-width: unset;
            width: 26px;
            &:before, &:after {
              display: none;
            }
            .icon {
              fill: $white;
            }
            &:hover {
              .icon {
                fill: $btn-background-color-hover;
              }
            }
          }
        }
      }
    }
  }
}

.page-main {
  .cms-page__content {
    max-width: unset;
  }
}

.cms-about,
.cms-about-us {
  .breadcrumbs {
    min-height: 0;
    padding: 0;
    .breadcrumbs__list {
      display: none;
    }
  }
  .page-main {
    .heading--page {
      display: none;
    }
    .container {
      width: 100%;
    }
    .slider-team {
      .slider__item {
        padding-right: 20px;
        > a {
          display: block;
          strong {
            margin: 10px 0 0;
            display: block;
            font-size: 16px;
          }
        }
      }
      .slider__navigation {
        display: block;
        top: 115%;
        .slider__prev {
          left: 0;
        }
        .slider__next {
          right: unset;
          left: 60px;
        }
      }
    }
  }
}

.employee-profile {
  figure {
    margin: 0;
    img {
      width: 100%;
    }
  }
  .detail {
    strong {
      font-family: $font-family__base;
      font-weight: 400;
    }
    .contact {
      padding: 10px 0;
      border-width: 2px 0 0;
      border-style: solid;
      border-color: $button__bg--icon;
      &.last {
        border-width: 2px 0 2px;
      }
      a {
        text-decoration: none;
        min-height: unset;
      }
    }
  }
}

.slider--products {
  .slider__navigation {
    top: 86%;
  }
  .slider__dots {
    display: none !important;
  }
}

.slider-clients {
  .slider__navigation {
    display: block;
    @include mq($max-screen: $screen-m) {
      top: 140%;
    }
    .slider__nav-button {
      &.slider__prev {
        left: 0;
      }
    }
  }
  .slider__nav-button {
    background-color: $border-color-base;
    .icon {
      fill: $white;
    }
    &:hover {
      background-color: $color-secondary;
      .icon {
        fill: $color-primary;
      }
    }
  }
  .slick-dots {
    bottom: -40px;
    li {
      height: 10px;
      width: 10px;
      button {
        height: 10px;
        width: 10px;
        background: $border-color-base;
      }
      &.slick-active {
        button {
          background: $color-secondary;
        }
      }
    }
  }
  .slider__item {
    padding: 20px 0;
    picture {
      opacity: 0.5;
      @include mq($max-screen: $screen-m) {
        opacity: unset;
      }
    }
    &:hover {
      a {
        transition: all 0.3s;
        transform: scale(1.2);
      }
      picture {
        opacity: 1;
      }
    }
  }
}

@include mq($screen-xl) {
  .cms-index-index {
    .home {
      > [data-element="main"] {
        width: 100%;
        margin: 0 auto;
        &.width-full-screen {
          max-width: unset;
          padding: 0;
        }
      }
    }
  }
}

