//
//  JetTheme compatibility
//  _____________________________________________

//
//  Common
//  ---------------------------------------------

.amfaq-search {
    .search-autocomplete {
        border-top: none;
        box-sizing: border-box;
    }

    li {
        display: flex;
        flex-direction: column;
    }

    .amfaq-category.selected {
        background: inherit;
    }
}

.amfaq-rating .average-rate-wrapper:not(:checked) > label {
    & {
        padding: 0;
    }

    &:before,
    &:after {
        position: static;
        border: none;
        border-radius: unset;
        background: none;
    }
}

.amfaq-rating-average {
    margin-right: 5px;
}

.ask-question-form .button-container .button-info {
    float: none;
}

//
//  Mobile
//  ---------------------------------------------
@include mq($max-screen: $screen__s - 1) {
    .amfaq-rating .amfaq-rating-average {
        & {
            flex-wrap: wrap;
        }

        .amfaq-rate-the-answer:first-of-type {
            width: 100%;
        }

        .average-rate-wrapper {
            padding-left: 0;
        }
    }
}

@include mq($max-screen: $screen__m - 1) {
    .amfaq-search,

    .amfaq-question-footer-wrapper {
        & {
            align-items: flex-start;
            flex-direction: column;
        }

    }

    .amfaq-search .search-autocomplete {
        margin: 0;
    }
}

//
//  Desktop
//  ---------------------------------------------
@include mq($min-screen: $screen-m){
    .amfaq-search .search-autocomplete {
        top: auto;
        padding: 0;
        border-radius: unset;
    }

    .amfaq-question-footer-wrapper {
        flex-wrap: wrap;
    }
}
