// Colors
$white                       : #fff !default;
$black                       : #000 !default;
$red                         : #e62325 !default;
$orange                      : #fab216 !default;
$yellow                      : #ffd500 !default;
$green                       : #13b200 !default;
$blue                        : #0075e4 !default;
$bright-sky-blue             : #00b8ff !default;
$teal                        : #0194ab !default;
$pink                        : #ff5b77 !default;
$purple                      : #613d7c !default;
$seafoam-blue                : #77ccb9 !default;
$blue-light-1                : #c0e9ff !default;
$blue-light-2                : #e1f3fd !default;
$blue-1                      : #00aeef !default;
$color-gray-middle2          : #c6c6c6 !default;
$graphite                    : #0C0C0C !default;
$lexicon                     : #F7F5F1 !default;
$concrete                    : #D9E4E0 !default;
$trace                       : #F2E880 !default;
$marble                      : #2A2C2E !default;

// Grayscale colors
$gray-darkest                : #1a1b1d !default;
$gray-darker                 : #393243 !default;
$gray-dark                   : #2A2C2E80 !default;
$gray                        : #d8d8d8 !default;
$gray-light                  : $lexicon !default;
$gray-lighter                : #fbfbfb !default;
$gray-lightest               : #f7f7f9 !default;
$gray-light-1                : #fafafa !default;
$gray-2                      : #707070 !default;
$gray-3                      : #DBE4E0 !default;

// Semantic color scheme
$color-primary               : $graphite !default;
$btn-color-secondary         : #131e29 !default;
$color-secondary             : $btn-color-secondary !default;
$btn-color-primary           : #ffffff !default;
$btn-background-color        : #131e29 !default;
$btn-background-color-hover  : #b68f82 !default;
$color-success               : $green !default;
$color-info                  : $blue !default;
$color-warning               : $red !default;
$color-error                 : $red !default;
$color-inverse               : $gray-dark !default;
$color-focus                 : $bright-sky-blue !default;
$color-hover-menu            : $blue-light-1 !default;
$color-background-menu-modal : $blue-light-2 !default;
$color-background-top-menu   : $gray-light-1 !default;
$color-background-link-button: $blue-1 !default;
$auth__link-color            : $color-primary !default;
$label-color                 : $color-primary !default;
$breadcrumbs__color          : $color-primary !default;
$input__button-pass-icon-fill: $color-primary !default;
$input__required-color       : $color-primary !default;
$pager__link-color           : $color-primary !default;
$tab__title-color            : $color-primary !default;
$checkbox__label-color       : $color-primary !default;
$toolbar__amount-color       : $color-primary !default;
$dropdown-list__icon-fill    : $color-primary !default;
$filters__trigger-icon-fill--open: $color-primary !default;
$password-strength__color    : $color-primary !default;
$button__before-background   : $color-secondary !default;
$link__color-hover   : #1f2a44 !default;
$button__background--secondary   : $color-primary !default;
$contact-us__tips-color              : $color-primary !default;
$forgot-password__info-color              : $color-primary !default;

// Background
$bg-color-base               : $white !default;
$bg-color-secondary          : $gray-lighter !default;
$bg-color-dark               : $gray-darkest !default;
$mega-menu__inner-link-before-bg : $color-secondary !default;

// Border
$border-color-base           : $gray-2 !default;
$border-width-base           : 1px !default;
$border-style-base           : solid !default;
$border-color-secondary      : $gray-dark !default;
$border-width-secondary      : 1px !default;
$border-style-secondary      : solid !default;

$border-base                 : $border-width-base $border-style-base $border-color-base !default;
$border-secondary            : $border-width-secondary $border-style-secondary $border-color-secondary !default;

// Outline
$outline-base                : none !default;

// Focus
$border-focus                : 2px solid $color-primary !default;
$border-focus-dark           : 2px solid $color-primary !default;

// Focus inline
$color-focus-inline          : $color-primary !default;
$color-focus-inline-dark     : $color-primary !default;
$bg-focus-inline             : $color-focus !default;
$bg-focus-inline-dark        : $color-primary !default;
$outline-focus-inline        : 0 !default;
$text-decoration-focus-inline: underline !default;
$focus-outline-offset        : -1px !default;
$focus-input-border          : $border-focus-dark;

// Spacers
$spacer--extra-small         : 4px !default;
$spacer                      : 8px !default;
$spacer--small               : 12px !default;
$spacer--medium              : 16px !default;
$spacer--semi-medium         : 24px !default;
$spacer--large               : 32px !default;
$spacer--semi-large          : 40px !default;
$spacer--extra-large         : 48px !default;
$main__margin                : 0 auto !default;
$product-view__title-margin  : 0 !default;

// Typography
$font-family-helvetica       : 'Helvetica Neue' !default;
$font-family-helvetica-bold  : 'Helvetica Neue Bold' !default;
$font-family-helvetica-heavy : 'Helvetica Neue Heavy' !default;
$font-family-anton-regular   : 'Anton Regular' !default;
$font-family-sans-serif      : sans-serif !default;
$font-family-serif           : serif !default;
$font-family-monospace       : Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !default;
$headings-font-family        : $font-family-helvetica-bold !default;
$filters__heading-font-family: $font-family-helvetica-bold !default;
$filters__heading-font-family: $font-family-helvetica-bold !default;

$font-family-base            : $font-family-helvetica, $font-family-sans-serif !default;
$font-family__base           : $font-family-helvetica-bold, $font-family-sans-serif !default;
$font-family-secondary       : $font-family-helvetica-heavy, $font-family-serif !default;

$font-color-base             : $color-primary !default;
$font-weight-normal          : 400 !default;
$font-weight__regular        : 400 !default;
$font-weight-medium          : 500 !default;
$font-weight-bold            : 700 !default;
$font-weight-base            : $font-weight-normal !default;
$font-line-height            : 1.6 !default;

$font-size-super-extra-large : 32px !default;
$font-size-extra-large       : 24px !default;
$font-size-large             : 18px !default;
$font-size-medium            : 16px !default;
$font-size-base              : 14px !default;
$font-size-small             : 12px !default;
$font-size-extra-small       : 10px !default;
$breadcrumbs__font-size      : $font-size-medium !default;
$filters__heading-font-weight: $font-weight__regular !default;

// Line heights
$line-height__base: 1.428571429;
$line-height__xl: 1.7;
$line-height__l: 1.5;
$line-height__s: 1.33;

// Indents
$indent__base: 20px; // 20px
$indent__xl: $indent__base * 2; // 40px
$indent__l: $indent__base * 1.5; // 30px
$indent__m: $indent__base * 1.25; // 25px
$indent__s: $indent__base * 0.5; // 10px
$indent__xs: $indent__base * 0.25; // 5px

// Z-index
$z-index-lowest              : -1 !default;
$z-index-initial             : auto !default;
$z-index-low                 : 1 !default;
$z-index-normal              : 10 !default;
$z-index-high                : 50 !default;
$z-index-highest             : 99 !default; // reserved for modals - check _modal-variables.scss for more

// Layout
$max-content-width           : 1328px !default;

// Layout - breakpoints
$screen-xs                   : 320px !default;
$screen-s                    : 480px !default;
$screen-m                    : 768px !default;
$screen-l                    : 1024px !default;
$screen-xl                   : 1200px !default;
$screen-xxl                  : 1600px !default;
$screen__xxs                 : 320px !default;
$screen__xs                  : 480px !default;
$screen__s                   : 640px !default;
$screen__m                   : 768px !default;
$screen__l                   : 1024px !default;
$screen__xl                  : 1440px !default;

// shadow
$shadow                      : 0 2px 6px 0 rgba(0, 0, 0, 0.3) !default;
$darken-layout-hover         : rgba($black, 0.2) !default;

// animations
$animation-position-top      : -300px !default;
$animation-position-right    : -300px !default;
$animation-position-left     : -300px !default;

// Components
$border-radius               : 0 !default;
$form-elements-radius        : 0 !default;
$form-elements-radius--small : 0 !default;
$form-elements-border-color  : $border-color-secondary !default;
$form-element-border-width   : 1px !default;
$form-element-border-style   : solid !default;
$form-elements-border        : $form-element-border-width $form-element-border-style $form-elements-border-color !default;
$form-elements-border-focus  : 2px solid $color-primary !default;
$form-input-box-shadow-focus : none !default;
$transition-base             : all 0.3s ease-in-out !default;
$transition-fade             : opacity 0.25s linear !default;
$list__item-last-child-padding: 0 !default;
$breadcrumbs__display\@mobile: block !default;
$product-grid-item__price-min-height: unset !default;
$list__item-padding          : 0 !default;

// Dropdown list
$dropdown-list__label-text-transform : capitalize !default;

// Contact bar
$contact-bar__link-text-transform--icons : capitalize !default;
$contact-bar__link-text-transform : capitalize !default;

//Filter
$active-filters__label-margin : 0 $spacer--small 0 0 !default;
$active-filters__headin-margin: $spacer 0 $spacer--small 30px !default;
$active-filters__heading-font-family: $font-family__base !default;
$active-filters__heading-font-weight: $font-weight__regular !default;
$layout-sidebar-width: 20% !default;

//Toolbar
$toolbar__mode-margin : 0 !default;

//Input
$input__placeholder-color    : $border-color-secondary !default;
$cart-discount__input-margin: 0 10px 0 0 !default;

//Button
$button__background: $color-secondary !default;
$button__text-color: #010101 !default;
$shadow: 0 3px 6px #00000029 !default;
$button__bg--icon: $marble !default;
$button__color-hover--link: $color-secondary !default;
$product-view__button-wishlist-background-color: $gray-3 !default;
$add-to-cart__min-height: 157px !default;
$button__padding: 17px 48px !default;
$button__icon-fill-hover--icon: $color-primary !default;
$minicart-content__action-button-padding: 17px 48px !default;
$button__before-content: none !default;
$button__after-content: none !default;
$button__background-hover--icon    : unset !default;
$wishlist__buttons-icon-fill--active: $color-primary !default;
$wishlist__buttons-icon-fill: $color-primary !default;
$wishlist__quantity-button-icon-fill--active: $color-primary !default;
$wishlist__quantity-button-icon-fill: $color-primary !default;

//Menu
$mega-menu__inner-list-padding--level1: 0 !default;
$mega-menu__link-font-family: $font-family__base !default;

//Banner
$banner__content-border: $spacer--extra-small solid $gray !default;
$banner__content-after-width-hover: 100%;
$banner__content-after-left: 0;


//Product Slider {
$product-grid-item__border: none !default;
$product-grid-item__details-margin: 30px 0 $spacer--medium !default;

//Swatch
$swatch__option-width    : 24px !default;
$swatch__option-height   : 24px !default;
$swatch__option-margin   : 0 20px 20px 0 !default;
