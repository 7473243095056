.catalog-category-view {
    .heading--page {
        display: none;
    }
}

.block-content.filter-content {
    .block-title {
        display: none;
    }
}

.catalog-product-view {
    .cut-sheet_finishes {
        display: flex;
        button {
            font-family: $font-family__base;
            font-weight: 400;
        }

        .modal-trigger {
            padding: 0;
        }
        .modal__container {
            padding: 60px 20px 25px;
            overflow-y: auto;
        }
        .modal__close-button {
            top: 6px;
            right: 6px;
        }

        .block-link {
            margin-right: 30px;
            .button--link {
                padding: 0;
            }
        }
    }

    .product-view__options {
        .product-view__swatch-option-title {
            padding-right: 5px;
            margin-bottom: 15px;
            font-size: 18px;
            font-family: $headings-font-family;
            font-weight: 400;
            color: $border-color-base;
            text-transform: uppercase;
        }
        .select2-selection {
            border-radius: 9px;
        }
    }

    .product-view__sku {
        color: $black;
    }

    .product-view__main-actions {
        .product-view__buttons {
            align-items: center;
            .button {
                .button__text, .btn-text {
                    font-size: 18px;
                }
                &:hover {
                    .button__text, .btn-text {
                        color: $btn-color-secondary;
                    }
                }
            }
            .add-quote-btn {
                display: none;
                flex-basis: 100%;
                order: 1;
                max-width: 223px;
                .button__icon {
                    display: none;
                }
                .amquote-addto-button {
                    width: 100%;
                    margin: 0;
                }
            }
            .amquote-addto-button,
            .tocart {
                height: 47px;
                margin-bottom: 20px;
                font-family: $font-family-base;
                text-transform: capitalize;
            }
            .product-view__addtowishlist {
                width: 42px;
                min-width: 42px;
                height: 47px;
                background: #DBE4E0;
                border-radius: 9px;
                &:before {
                    content: none;
                }
                &:hover {
                    .icon {
                        use {
                            fill: $color-primary;
                        }
                    }
                }
                .icon {
                    use {
                        fill: transparent;
                    }
                }
            }
        }
    }

    .product-buttons-sticky {
        min-height: unset;
        .amquote-addto-button {
            max-width: 300px;
            height: 48px;
            order: 1;
            margin: 10px 0;
            width: 100%;
        }
        .add-to-cart__action {
            flex-flow: wrap;
            justify-content: flex-end;
            .add-to-cart__button-more {
                order: 0;
                flex-basis: 45px;
                margin-bottom: 5px;
                background: none;
            }
            .add-quote-btn {
                order: 1;
                width: 100%;
            }
            .tocart {
                height: 48px;
                width: 100%;
                order: 2;
                .button__text {
                    display: block;
                }
                .button__icon {
                    display: none;
                }
            }
            .towishlist {
                border-radius: 25px;
                min-width: 46px;
                min-height: 46px;
                height: 46px;
                width: 46px;
                .icon {
                    width: 18px;
                    fill: $color-secondary;
                }
                &:before, &:after {
                    content: none;
                }
                &:hover {
                    background-color: $color-secondary;
                    .icon {
                        fill: $button__bg--icon;
                    }
                }

                &.button--icon-filled {
                    .icon {
                        fill: $white;
                    }
                }
            }
        }
        button {
            &.action {
                &.primary {
                    font-size: 18px;
                    font-family: $font-family-base;
                    text-transform: capitalize;
                }
            }
        }
        .add-to-cart__title {
            .heading {
                white-space: initial;
            }
        }
        .add-to-cart__action-secondary {
            top: -57px;
        }
    }

    .product-name-mobile {
        h1 {
            font-size: 32px;
        }
    }
    .product-view__title {
        display: none;
    }
    .product-view {
        padding: 0;
        margin: 0 22px;
        .product-view__wrapper {
            max-width: 100%;
        }
        .product-view__gallery {
            .gallery {
                @include mq($screen-l) {
                    .fotorama {
                        .fotorama__stage {
                            margin-left: 37px;
                        }
                    }
                }
            }
        }
        .product-view__price {
            padding: 0;
        }
        .product-view__form {
            max-width: 273px;
            border: none;
        }
        .product-view__qty {
            display: flex;
            align-items: center;
            margin-top: 20px;
            label {
                font-family: $headings-font-family;
                color: $border-color-base;
                font-size: 18px;
                margin-right: 20px;
            }
            .quantity-update {
                align-items: center;
                .quantity-update__button {
                    border: none;
                    background-color: unset;
                    &:before {
                        content: none;
                    }
                    &:hover {
                        .quantity-update__icon {
                        }
                    }
                    .quantity-update__icon {
                        width: 15px;
                        fill: $color-primary;
                    }
                }
                .quantity-update__input {
                    border: 1px solid $button__bg--icon;
                    border-radius: 9px;
                    height: 35px;
                    width: 120px;
                }
            }
        }
        .product-page-info {
            .icon {
                height: 34px;
                width: 34px;
            }
            strong {
                font-family: $font-family__base;
                font-weight: 400;
            }
            .pagebuilder-column-group {
                flex-wrap: nowrap;
                .pagebuilder-column {
                    flex-basis: unset;
                }
            }
        }
        .product-view__tabs {
            .product-view__tab {
                .tab__title {
                    font-family: $font-family__base;
                    font-weight: 400;
                    @include mq($screen-l) {
                        border-width: 0 3px 0;
                        border-style: solid;
                        border-color: $button__bg--icon;
                        &:first-of-type {
                            border-left: none;
                        }
                        &:last-of-type {
                            border-right: none;
                        }
                    }
                    @include mq($max-screen: $screen-l - 1) {
                        background: none;
                        .tab__icon {
                            fill: $black;
                        }
                    }
                }
            }
        }
        .section {
            &.related {
                margin-top: 60px;
                .section__heading {
                    justify-content: flex-start;
                    h2 {
                        font-family: $font-family-helvetica-bold;
                        text-transform: capitalize;
                    }
                }
                .slider__navigation {
                    top: 70%;
                    .slider__prev {
                        left: -20px;
                        @include mq($max-screen: $screen-m - 1) {
                            left: 0;
                        }
                    }
                    .slider__next {
                        right: 0;
                    }
                }
                .slider__dots {
                    display: none !important;
                }
            }
        }
    }

    .fotorama-item {
        .fotorama__nav {
            @include mq($screen-l) {
                width: 150px !important;
                overflow-y: auto;
                overflow-x: unset;
            }
            @include mq($max-screen: $screen__l - 1) {
                height: 120px !important;
                overflow-x: auto;
                .fotorama__nav__frame {
                    display: inline-block;
                    height: 96px;
                    .fotorama__img {
                        height: 100%;
                        transform: translateY(-50%) translateX(-50%);
                        width: unset;
                        left: 50%;
                    }
                }
            }
            .fotorama__thumb__arr,
            .fotorama__nav__frame--dot {
                display: none !important;
            }
        }
    }
}

.amquote-addto-button {
    text-transform: capitalize;
    font-family: $font-family-base !important;
    .button__icon {
        fill: $white;
    }
}

.catalog-product_compare-index {
    .actions {
        .clear-all-action {
            width: 160px;
            margin-bottom: 20px;
            @include mq($screen-m) {
                text-align: center;
            }
        }
    }
}

.page-products {
    .header-marketing-bar--close {
        .breadcrumbs {
            margin-top: 162px;
        }
    }
    .heading--page {
        text-align: center;
    }
    .category-view {
        margin-bottom: 20px;

        .category-description {
            padding: 0 15px;
        }

        .um-descr-show-button,
        .um-descr-hide-button {
            padding: 0 15px;
            display: block;
            margin-left: auto !important;
            margin-right: auto !important;
            max-width: $container__max-width;
            text-align: right;
        }
    }

    .product-grid-item__primary-action {
        display: flex;
        .amquote-addto-button {
            width: 48px;
            padding: 0 8px;
            order: 1;
        }
        .tocart {
            order: 1;
            margin-left: 5px;
        }
    }
    .product-grid-item__details-bottom {
        display: block;
        .product-grid-item__price {
            min-height: unset;
        }
        .product-grid-item__actions {
            float: right;
        }
    }

    #amasty-shopby-product-list {
        .toolbar {
            .toolbar__sorter-link {
                min-height: 48px;
                background: none;
                &:hover {
                    background: $color-primary;
                }
            }
            .toolbar__pager {
                .link {
                    background: none;
                    &:hover {
                        &:before {
                            background-color: $btn-color-secondary;
                        }
                    }
                }
                .button {
                    .icon {
                        fill: $color-primary;
                        stroke: transparent;
                    }
                    &:hover {
                        background-color: $border-color-base;
                    }
                }
            }
        }
        .product-grid-item {
            display: flex;
            .product-grid-item__name-link {
                font-family: $font-family-base;
            }
        }
    }
}

.slider--products {
    max-width: 100vw;
    .product-image-container {
        max-height: 250px;
    }
}

#layered-filter-block {
    .list-category {
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            .top-cat {
                position: relative;
                &.active {
                    .sub-category {
                        li {
                            height: 35px;
                            opacity: 1;
                        }
                    }
                    .collapse {
                        display: flex;
                    }
                    .expand {
                        display: none;
                    }
                }
            }
            .current {
                > a {
                    font-weight: 600;
                }
            }
            .buttons {
                position: absolute;
                right: -12px;
                top: -8px;
                .icon {
                    width: 16px;
                    height: 16px;
                }
            }
            .sub-category {
                margin-left: 20px;
                li {
                    transition: all .3s ease-in-out;
                    height: 0;
                    opacity: 0;
                }
            }
        }
        .collapse {
            display: none;
        }
    }
}

@include mq($min-screen: $screen__l) {
    .catalog-product-view {
        .product-buttons-sticky {
            .amquote-addto-button {
                width: 300px;
            }
        }
    }
}

@include mq($min-screen: $screen__m) {
    .catalog-product-view {
        .product-view__title {
            display: block;
        }
        .product-view {
            margin: 0 auto;
            .section {
                &.related {
                    .section__heading {
                        display: none;
                    }
                }
            }
        }
        .product-view__main-actions {
            .product-view__buttons {
                .add-quote-btn {
                    display: block;
                }
            }
        }

        .product-buttons-sticky {
            .amquote-addto-button {
                height: 56px;
                min-width: 165px;
                padding: 0 32px;
                .icon {
                    display: none;
                }
            }
            .add-to-cart__action {
                flex-flow: row nowrap;
                .add-to-cart__action-secondary {
                    order: 2;
                    width: 130px;
                    top: unset;
                }
                .add-quote-btn {
                    order: 0;
                }
                .tocart {
                    order: 1;
                    height: 56px;
                    margin-left: 10px;
                }
                .towishlist {
                    background-color: $color-secondary;
                }
            }
        }
    }
    .slider--products {
        .product-item {
            padding: 8px 30px;
        }
    }
}