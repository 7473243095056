//
//  Mixins
//  ____________________________________________

@mixin am-flex(
    $horiz-pos: none,
    $vert-pos: none,
    $wrap: none,
    $inline: none
) {

    @if $inline == none {
        display: flex;
    } @else {
        display: $inline;
    }

    @if $horiz-pos != none {
        justify-content: $horiz-pos;
    }

    @if $vert-pos != none {
        align-items: $vert-pos;
    }

    @if $wrap != none {
        flex-wrap: $wrap;
    }
}

.ambase-word-break {
    & {
        word-wrap: break-word;
        word-break: break-word;
    }

    .ie11 & {
        word-break: break-all;
    }
}
