button.action.primary,
button.primary,
button.action-primary,
button.account-form__button,
button.product-view__update-cart,
button.minicart-content__action-button,
.button.primary,
.actions-group__button,
.wishlist__button,
a.pagebuilder-button-primary,
a.pagebuilder-button-secondary,
.cart-grid__button,
.slider__nav-button {
    box-shadow: $shadow;
    border-radius: 10px;
    border: none;
    font-family: $font-family__base;
    font-weight: 400;
    &:before, &:after {
        content: none;
    }
}

button.action.primary,
button.primary,
button.action-primary,
.wishlist__button,
.actions-group__button,
button.account-form__button,
button.product-view__update-cart,
button.minicart-content__action-button,
.button.primary,
.cart-grid__button {
    background-color: $btn-background-color;
    color: $btn-color-primary;
}

button.slider__nav-button {
    background-color: unset;
}

a.pagebuilder-button-secondary {
    background-color: $btn-background-color;
    color: $btn-color-primary;
    &:hover {
        background-color: $btn-background-color-hover;
        color: $btn-color-secondary;
    }
}

a.pagebuilder-button-primary,
button.action.primary,
.wishlist__button,
.actions-group__button,
button.account-form__button,
button.action-primary,
.cart-grid__button,
button.product-view__update-cart,
button.minicart-content__action-button,
button.primary {
    background-color: $btn-background-color;
    color: $btn-color-primary;
    &:hover {
        background-color: $btn-background-color-hover;
        color: $btn-color-secondary;
    }
}

.button--icon {
    background-color: unset;
}

.button, a {
    -webkit-tap-highlight-color: transparent;
}

a.button--secondary,
button.button--secondary,
button.button.button--secondary.action,
.button-white {
    border: 2px solid $button__bg--icon;
    border-radius: 9px;
    font-size: 12px;
    font-weight: 400;
    font-family: $font-family__base;
    background-color: $btn-background-color;
    color: $btn-color-primary;
    &:after,
    &:before {
        content: none !important;
    }
    &:hover {
        background-color: $btn-background-color-hover;
        color: #131e29;
        border-color: $btn-color-secondary;
    }
    &:disabled,
    &[disabled] {
        border-color: $color-primary;
    }
}
.action-dismiss {
    margin-right: 10px;
}
.slider__nav-button {
    width: 42px;
    height: 43px;
    &.slider__prev {
        left: -10px;
    }
    &.slider__next {
        right: 0;
    }
    &:hover {
        border: none;
        background-color: $gray-2;
    }
}

.product-item {
    .tocart {
        background-color: $button__bg--icon;
        border-radius: 25px;
        min-width: 42px;
        min-height: 42px;

        &.button--icon-filled {
            .icon {
                fill: $white;
            }
        }

        &:before, &:after {
            content: none;
        }

        .icon {
            width: 16px;
            fill: $color-secondary;
        }

        &:hover {
            background-color: $color-secondary;

            .icon {
                fill: $button__bg--icon;
            }
        }

        &:focus {
            background-color: $gray-light;
        }

        position: absolute;
        top: 50px;
        right: 0;
    }

    .add-to-wishlist {
        background-color: #ffffff;
        border-radius: 25px;
        border-width: 0.5px;
        border-style: solid;
        border-color: #1f2a44;
        min-width: 42px;
        min-height: 42px;

        &.button--icon-filled {
            .icon {
                fill: $white;
            }
        }

        &:before, &:after {
            content: none;
        }

        .icon {
            width: 16px;
            fill: #1f2a44;
            border-color: #1f2a44;
        }

        &:hover {
            background-color: #1f2a44;

            .icon {
                fill: $white;
            }
        }
    }

    .tocart {
        position: absolute;
        top: 50px;
        right: 0;
    }
    .product-grid-item__rating {
        height: unset;
        margin-bottom: 0;
    }
    .product-grid-item__name {
        a {
            font-family: $font-family__base;
            padding: 0 10px;
        }
    }
    .amquote-addto-button {
        position: absolute;
        top: 58%;
        left: 50%;
        transform: translate(-50%, 0);
        padding: 15px 30px;
        min-width: 170px;
        font-size: 16px;
    }
    @include mq($screen-m) {
        button.amquote-addto-button {
            top: 50%;
        }
    }
    @include mq($screen-xl) {
        button.amquote-addto-button {
            top: 60%;
        }
    }
    @include mq($screen-l) {
        .amquote-addto-button {
            display: none !important;
        }
        &.product-grid-item,
        .product-item-info {
            &:hover {
                .amquote-addto-button {
                    display: block !important;
                }
            }
        }
    }
    @include mq($max-screen: $screen-s) {
        .amquote-addto-button {
            padding: 5px;
            min-width: 135px;
        }
    }
}

.font-helvetica-heavy,
.font-helvetica-heavy p,
.font-helvetica-heavy a,
.font-helvetica-heavy strong {
    font-family: $font-family-secondary;
    font-weight: 400;
}

.font-helvetica-bold,
.font-helvetica-bold p,
.font-helvetica-bold a,
.font-helvetica-bold strong {
    font-family: $font-family__base;
    font-weight: 400;
}

.modal,
.modal-popup {
    .modal__close-button {
        background-color: unset;
    }
}
.slider--products {
    @include mq($max-screen: $screen-m - 1) {
        .slider__handler {
            display: flex;
            flex-direction: column;
            .slider__container {
                order: 1;
            }
            .slider__navigation {
                order: 2;
                display: block;
                position: relative;
                .slider__prev {
                    left: 0;
                }

                .slider__next {
                    right: unset;
                    left: 60px;
                }
            }
        }
    }
}

.pagebuilder-slider {
    .slick-dots {
        bottom: 30px;
        li {
            margin: 0 10px;
            vertical-align: unset;
            button {
                background: transparent !important;
                border-width: 7px;
                height: 20px;
                width: 20px;
                &:hover {
                    border-width: 7px;
                }
            }
            &.slick-active {
                button {
                    height: 24px;
                    width: 24px;
                    border-color: $color-secondary !important;
                    border-width: 9px;
                    border-radius: 15px;
                }
            }
        }
    }
}

.pager__link {
    .icon {
        stroke: $color-primary;
    }
}
figure {
    margin: 0;
}

%arrow:after {
    border-color: transparent $color-primary $color-primary transparent;
}
