.amasty_quote-cart-index {
    .page-main {
        #shopping-cart-table {
            .cart-list-item__label {
                font-weight: 600;
            }
            .cart-grid__header-items {
                flex-basis: 60%;
            }
            .cart-grid__header-price,
            .cart-grid__header-subtotal {
                flex-basis: 14%;
            }
            .cart-grid__header-qty {
                flex-basis: 12%;
                .cart-list-item__input {
                    width: 100%;
                    max-width: 70px;
                }
            }
            .cart-list-item {
                .cart-list-item__left {
                    flex-basis: 55%;
                    .product-image-photo {
                        height: auto;
                    }
                }
                .cart-list-item__right {
                    flex-basis: 45%;
                    margin: 0;
                    padding: 0;
                    .cart-list-item__details {
                        display: flex;
                        .cart-list-item__price {
                            .price {
                                display: flex;
                                .currency-symbol {
                                    margin-right: 5px;
                                }
                                .amquotereq-input {
                                    text-align: center;
                                    width: 100%;
                                    max-width: 100px;
                                    height: 48px;
                                    margin: 0 8px 0 0;
                                    padding: 0 8px;
                                }
                            }
                        }
                        .cart-list-item__input {
                            &.qty {
                                margin: 0 8px;
                            }
                        }
                        .cart-list-item__total {
                            padding-left: 15px;
                        }
                        .cart-list-item__qty {
                            flex-direction: column;
                        }
                    }
                }
            }
        }
        .form-cart {
            .actions {
                justify-content: flex-end;
                flex-direction: row;
                #update-quote-cart {
                    height: 1px;
                    left: -9999px;
                    position: absolute;
                    width: 1px;
                }
            }
        }
        #quote-details {
            .title {
                border-bottom: 1px solid $gray;
                display: block;
                margin: 12px 0;
                padding-bottom: 5px;
            }
            .form-login {
                border-bottom: 1px solid $gray;
                #customer-email-fieldset {
                    ._with-tooltip {
                        position: relative;
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        #customer-email {
                            max-width: 100%;
                            margin-right: 0;
                        }
                        #customer-email-error {
                            order: 2;
                        }
                        .field-tooltip {
                            order: 1;
                            cursor: pointer;
                            &._active {
                                z-index: 100;
                                .field-tooltip-content {
                                    display: block;
                                }
                            }
                            .action-help {
                                svg {
                                    width: 22px;
                                    height: 30px;
                                }
                            }
                            .field-tooltip-content {
                                display: none;
                                background: #f4f4f4;
                                border: 1px solid $gray;
                                border-radius: 1px;
                                font-size: 14px;
                                padding: 12px;
                                width: 270px;
                                right: 20px;
                                position: absolute;
                                text-transform: none;
                                top: 48px;
                                word-wrap: break-word;
                                z-index: 2;
                            }
                        }
                        input {
                            margin-right: 10px;
                            order: 0;
                        }
                    }
                }
                #customer-password {
                    ~ .note {
                        display: block;
                        margin: 15px 0;
                    }
                }
                .actions-toolbar {
                    .login {
                        margin-bottom: 15px;
                    }
                }
            }
            .fieldset {
                &:last-child {
                    margin-bottom: 20px;
                }
                .field {
                    margin: 10px 0;
                    &.required,
                    &._required {
                        .label {
                            &:after {
                                color: $red;
                                content: '*';
                                white-space: nowrap;
                            }
                        }
                    }
                }
            }
            input,
            textarea {
                width: 100%;
                min-height: 36px;
            }
        }
    }
}

.amasty_quote-account-view {
    .dashboard__content {
        .heading--page {
            display: inline-block;
            margin-right: 25px;
        }

        .order-status {
            border: 2px solid $base-border__color;
            border-radius: 3px;
            display: inline-block;
            margin: 6px 0 0;
            text-transform: uppercase;
            vertical-align: top;
            padding: 5px 10px;
        }

        .order-date {
            margin: 10px 0 18px;
        }

        .order-details-items {
            margin-bottom: 40px;

            .am-quotes-head {
                th.image {
                    width: 200px;
                }

                th.name-sku {
                    width: 600px;
                }
            }
        }

        .block-order-details-comments {
            margin-bottom: 50px;
            .block-title {
                border-bottom: 1px solid $color-gray-middle2;
                margin-bottom: 25px;
                padding-bottom: 10px;
                strong {
                    font-size: 2.2rem;
                    font-weight: 300;
                }
            }

            .block-content {
                .note-list {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    padding: 0;
                    .note-list-item {
                        display: inline-block;
                        width: 48%;
                        font-weight: 700;
                        margin-right: 20px;
                        min-width: 200px;
                        margin-bottom: 20px;
                        .note-list-comment {
                            font-weight: 400;
                            word-break: break-word;
                            word-wrap: break-word;
                        }
                    }
                }

                .note-list.amquote-custom-fields {
                    display: grid;
                    grid-auto-flow: column;
                    justify-content: normal;

                    li.note-list-item.amquote-item:nth-child(n + 4) {
                        grid-column: 2;
                    }
                }
            }
        }
    }
}

@include mq($min-screen: $screen__m) {
    .amasty_quote-cart-index {
        .page-main {
            .form-cart {
                .actions {
                    .update {
                        margin-left: 10px;
                    }
                }
            }
            #shopping-cart-table {
                .cart-list-item {
                    .cart-list-item__right {
                        .cart-list-item__details {
                            .cart-list-item__qty {
                                flex-direction: row;
                                .cart-list-item__input {
                                    &.qty {
                                        margin: 0 8px 0 30px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}

@include mq($max-screen: $screen__m) {
    .amasty_quote-account-view {
        .dashboard__content {
            .block-order-details-comments {
                .block-content {
                    .note-list.amquote-custom-fields {
                        display: block;
                    }
                }
            }
        }
    }

    .amasty_quote-account-view {
        .dashboard__content {
            .order-details-items {

                .am-quotes-head {
                    td.name-sku span{
                        display: contents;
                    }
                }
            }
        }
    }
}
