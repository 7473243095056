@import 'header-variables';
@import 'button';
@import 'search-form';

.header {
  position: relative;
  background: $header__background;

  @include mq($screen-l) {
    border: none;
  }

  &--checkout {
    position: static;

    .header__wrapper {
      padding: $header__wrapper-padding--checkout;

      @include mq($screen-m) {
        padding: $header__wrapper-padding--checkout\@medium;
      }
    }

    .header__minicart {
      border: none;
    }

    @include mq($screen-l) {
      border: $header__border;
      border-width: $header__border-width;
    }
  }

  &--email {
    background-color: $header__background--email;
  }

  &__wrapper {
    position: relative;
    justify-content: flex-end;
    display: flex;
    flex-flow: row wrap;
    padding: 5px 0;

    @include mq($screen-m) {
      flex-wrap: nowrap;
      padding: $header__wrapper-padding\@medium;
    }

    @include mq($screen-l) {
      border: $header__border\@large;
      border-width: $header__border-width\@large;
      padding: $header__wrapper-padding\@large;
    }
  }

  &__logo {
    padding: 0;
    order: 1;
    justify-content: flex-start;
    max-width: unset;
    max-height: 25px;
    margin-top: 10px;
    @include mq($screen-l) {
      max-height: 35px;
      margin-top: 15px;
    }
    @include mq($screen-m) {
      max-height: 30px;
      margin-top: 10px;
      padding: 0 0 0 10px;
    }

    .logo__image {
      height: 100%;
      max-height: 46px;
    }
  }

  &__search-wrapper {
    display: none;
    flex-flow: column nowrap;
    flex-grow: $header__search-wrapper-flex-grow;
    flex-basis: $header__search-wrapper-flex-basis;
    order: $header__search-wrapper-order;
    justify-content: center;
    padding: $header__search-wrapper-padding;
    z-index: $header__search-wrapper-z-index;

    @include mq($screen-m) {
      order: $header__search-wrapper-order\@medium;
      flex-direction: column;
      justify-content: center;
      padding: $header__search-wrapper-padding\@medium;
    }

    @include mq($screen-l) {
      display: flex;
      padding: 0 68px 0 0 ;
    }

    @include mq($screen-xl) {
      padding: $header__search-wrapper-padding\@extra-large;
    }

    .search-form__input,
    .search-form__button {
      border-color: $border-color-secondary;
      background: none;
      &::placeholder {
        font-size: 12px;
        color: $border-color-secondary;
      }
      &:-ms-input-placeholder {
        font-size: 12px;
        color: $border-color-secondary;
      }

      &::-ms-input-placeholder {
        font-size: 12px;
        color: $border-color-secondary;
      }
    }
    .search-form__button {
      &:hover {
        &:before {
          background-color: $color-secondary;
        }
      }
    }
  }

  &__minicart {
    position: relative;
    padding-left: $header__minicart-padding-left;
    border: $header__minicart-border\@medium;
    border-width: $header__minicart-border-width\@medium;
  }

  &__minicart-modal {
    z-index: $header__minicart-modal-z-index; // to cover paypal button iframe
  }

  .button--icon-fill {
    margin-right: $header__button-wishlist-margin-right;
  }

  &__buttons {
    display: flex;
    flex: 1 $header__buttons-width;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;
    order: 3;
    max-width: calc(3 * 30px + 1 * 8px);
    @include mq($screen-s) {
      max-width: $header__buttons-width\@medium;
    }
    @include mq($screen-xl) {
      flex: 1 $header__buttons-width\@extra-large;
      max-width: $header__buttons-width\@extra-large;
    }

    .button-compare-link {
      .compare-link {
        align-items: center;
        &:hover {
          text-decoration: none;
        }

        position: relative;
      }

      @include mq($screen-m) {
        border: 1px solid #d8d8d8;
        border-width: 0 0 0 1px;
        padding: 0 8px;
      }
    }
    .button--icon {
      background: none;
      min-width: 38px;
      @include mq($screen-s) {
        min-width: 48px;
      }
      &.wishlist-button {
        .icon {
          fill: transparent;
        }
        &:hover {
          fill: $color-primary;
        }
      }
    }
  }

  &__brief-info {
    display: $header__brief-info-display;
    align-items: center;
    order: $header__brief-info-order;
    min-width: 250px;

    @include mq($screen-l) {
      display: $header__brief-info-display\@large;
    }

    .brief-info__subtitle {
      font-size: $header__brief-info-subtitle-font-size;
      color: $color-primary;
    }
  }

  .side-menu {
    order: 4;
    .side-menu__trigger {
      border: none;
      transform: none;
      padding: 0;
      outline: none;
      margin-top: 5px;
      min-width: 45px;
      
      svg.side-menu__trigger-icon {
        width: 30px;
        height: 30px;
      }
    }
    .modal {
      left: unset;
      right: 0;
      overflow-x: hidden;
      border: 1px solid $border-color-base;
      align-items: flex-end;
      background: #000000b3;
      width: 0;
      display: flex;
      .modal__container {
        transition: 0.3s;
        width: 0;
        padding: 0 28px;
        max-width: 276px;
        margin-top: 0;
        max-height: unset;
        height: 100%;
        .modal__close-button {
          justify-content: flex-end;
          right: 30px;
          top: 0;
          padding: 0;
          min-height: 40px;
        }
        .modal__content {
          margin-top: 0;
          padding-top: 40px;
          .modal__middle {
            padding: 0 0 10px;
            > .dropdown-list {
              margin-bottom: 50px;
              > .dropdown-list__list {
                > .dropdown-list__item {
                  border: none;
                  .dropdown-list__label {
                    &:hover {
                      color: $color-inverse;
                    }
                  }
                  > .dropdown-list__label {
                    font-size: 20px;
                    text-transform: uppercase;
                    padding: 0 0 15px;
                    font-family: $font-family__base;
                    font-weight: 400;
                    &[aria-expanded="true"] {
                      color: $color-inverse;
                      .dropdown-list__icon {
                        fill: $color-inverse;
                        right: 0;
                        transform: unset;
                      }
                    }
                    .dropdown-list__icon {
                      margin: 0;
                      height: 30px;
                      width: 30px;
                      right: 0;
                      fill: $color-primary;
                      transform: rotate(-90deg);
                    }
                  }
                  .dropdown-list__content {
                    .dropdown-list--inner {
                      padding: 0;
                      .dropdown-list__label {
                        color: $color-primary;
                        padding: 0 0 10px;
                      }
                    }
                  }
                }
              }
            }
          }
          #search_mini_form_mobile {
            .search-form__button {
              left: 0;
              right: unset;
              background: none;
              border: none;
              justify-content: flex-start;
              min-height: 30px;
              height: 30px;
              padding: 0;
              top: 5px;
            }
            .search-form__input,
            .search-form__input.focus-visible {
              margin-left: 35px;
              border-width: 0 0 1px 0;
              border-color: $border-color-secondary;
              outline: none;
              width: auto;
              padding: 0;
              height: 30px;
              font-size: 16px;
              &::placeholder {
                font-size: 16px;
                color: $color-primary;
              }
              &:-ms-input-placeholder {
                font-size: 16px;
                color: $color-primary;
              }

              &::-ms-input-placeholder {
                font-size: 16px;
                color: $color-primary;
              }
            }
          }
          .account-menu-mobile {
            color: $button__bg--icon;
            margin: 20px 0;
            .learn-more {
              text-decoration: underline;
              margin-bottom: 20px;
            }
            .buttons {
              display: flex;
              a {
                border-radius: 9px;
                padding: 0 20px;
                margin-right: 10px;
                font-size: 12px;
                text-transform: capitalize;
                &.register {
                  font-weight: 500;
                }
              }
            }
          }
          .side-menu__content-bottom {
            padding: 0;
            .contact-bar--vertical {
              .contact-bar__list--icons {
                margin: 0;
                border: none;
              }
              .contact-bar__list {
                display: none;
                &.menu-mobile {
                  display: block;
                  .contact-bar__item {
                    display: block;
                    .contact-bar__link {
                      padding: 10px 0;
                    }
                    .contact-bar__text {
                      margin-left: 10px;
                    }
                    &.social-links {
                      display: flex;
                      .contact-bar__link {
                        margin-right: 15px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.modal--active {
        width: 100%;

        .modal__container {
          width: 276px;
        }
      }
    }
  }
}

.sticky-header {
  position: fixed;
  width: 100%;
  z-index: 90;
  background-color: $white;
  display: flex;
  flex-direction: column;
  @include mq($screen-l) {
    display: block;
  }
  .mega-menu {
    .mega-menu__inner-link {
      font-family: $font-family__base;
      font-size: 13px;
      font-weight: 400;
      &:hover {
        background-color: $color-secondary;
      }
    }
  }

  ~ {
    #maincontent,
    nav {
      margin-top: 105px;
      @include mq($screen-m) {
        margin-top: 122px;
      }
      @include mq($screen-l) {
        margin-top: 170px;
        ~ #maincontent {
          margin-top: 0;
        }
      }
      ~ #maincontent {
        margin-top: 0;
      }
    }
  }

  .marketing-bar {
    background-color: $color-secondary;
    .marketing-bar__text {
      color: $color-primary;
      font-family: $font-family-secondary;
      padding: 12px 0 10px;
      text-align: center;
    }
    .marketing-bar__close {
      .button__icon {
        fill: $color-primary;
      }
    }
  }
}

.checkout-index-index {
  .sticky-header {
    z-index: 9999;
    ~ {
      #maincontent {
        margin-top: 81px;
      }
    }
  }
}

.messages__wrap {
  position: fixed;
  left: 0;
  z-index: 51;
  top: 130px;
  @include mq($screen-m) {
    top: 163px;
  }
  @include mq($screen-l) {
    top: 229px;
  }
}

.page-products {
  .messages__wrap {
    top: 131px;
    @include mq($screen-m) {
      top: 163px;
    }
    @include mq($screen-l) {
      top: 230px;
    }
  }

  .header-marketing-bar--close {
    .messages__wrap {
      top: 95px;

      @include mq($screen-m) {
        top: 117px;
      }
      @include mq($screen-l) {
        top: 183px;
      }
    }
  }
}

.header-marketing-bar--close {
  .messages__wrap {
    top: 95px;
    @include mq($screen-m) {
      top: 117px;
    }
    @include mq($screen-l) {
      top: 172px;
    }
  }
  .sticky-header ~ {
    #maincontent,
    nav {
      margin-top: 96px;
      @include mq($screen-m) {
        margin-top: 118px;
      }
      @include mq($screen-l) {
        margin-top: 184px;
      }
      ~ #maincontent {
        margin-top: 0;
      }
    }
  }
}

@include mq($screen-l) {
  .footer {
    .footer__handler {
      .footer__social {
        flex-direction: row;
        > div {
          display: block;
        }
      }
    }
  }
}

@include mq($max-screen: $screen__xs - 1) {
  .footer {
    .footer__handler {
      .footer__social {
        > div {
          .footer__social-list-title {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.footer__social-list-title {
  margin: 0 16px 16px 0;
}
